.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #2F2D47;
  font-size: calc(10px + 2vmin);
  color: #D7DEDC;
  min-height: 100vh;
  text-align: center;
}

.App-header {
  color: #EA526F;
}

.App-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

.App-link:hover {
  color: #EA526F;
}

img {
  max-width: 400px;
  height: auto;
  aspect-ratio: 695/676;
}

p {
  max-width: 800px;
}

@media only screen and (max-width: 800px) {
  img {
    max-width: 60%;
    height: auto;
  }
}

